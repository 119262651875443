/* press-start-2p-regular - cyrillic_latin */
@font-face {
  font-family: 'Press Start 2P';
  font-style: normal;
  font-weight: 400;
  src: url('press-start-2p/press-start-2p-v8-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Press Start 2P Regular'), local('PressStart2P-Regular'),
    url('press-start-2p/press-start-2p-v8-cyrillic_latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('press-start-2p/press-start-2p-v8-cyrillic_latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('press-start-2p/press-start-2p-v8-cyrillic_latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('press-start-2p/press-start-2p-v8-cyrillic_latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('press-start-2p/press-start-2p-v8-cyrillic_latin-regular.svg#PressStart2P')
      format('svg'); /* Legacy iOS */
}
