/* monoton-regular - latin */
@font-face {
  font-family: 'Monoton';
  font-style: normal;
  font-weight: 400;
  src: url('monoton/monoton-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Monoton'), local('Monoton-Regular'),
    url('monoton/monoton-v9-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('monoton/monoton-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('monoton/monoton-v9-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('monoton/monoton-v9-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('monoton/monoton-v9-latin-regular.svg#Monoton') format('svg'); /* Legacy iOS */
}
