/* raleway-100 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 100;
  src: url('raleway/raleway-v13-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Raleway Thin'), local('Raleway-Thin'),
    url('raleway/raleway-v13-latin-100.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-100.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-100.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-100.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('raleway/raleway-v13-latin-100.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-100italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 100;
  src: url('raleway/raleway-v13-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Thin Italic'), local('Raleway-ThinItalic'),
    url('raleway/raleway-v13-latin-100italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-100italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-100italic.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-100italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-100italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-200 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 200;
  src: url('raleway/raleway-v13-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
    url('raleway/raleway-v13-latin-200.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-200.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-200.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-200.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('raleway/raleway-v13-latin-200.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-200italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 200;
  src: url('raleway/raleway-v13-latin-200italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraLight Italic'), local('Raleway-ExtraLightItalic'),
    url('raleway/raleway-v13-latin-200italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-200italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-200italic.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-200italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-200italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-300 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('raleway/raleway-v13-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Raleway Light'), local('Raleway-Light'),
    url('raleway/raleway-v13-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-300.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('raleway/raleway-v13-latin-300.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-300italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 300;
  src: url('raleway/raleway-v13-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Light Italic'), local('Raleway-LightItalic'),
    url('raleway/raleway-v13-latin-300italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-300italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-300italic.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-300italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-300italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-regular - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('raleway/raleway-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Raleway'), local('Raleway-Regular'),
    url('raleway/raleway-v13-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-regular.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url('raleway/raleway-v13-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Italic'), local('Raleway-Italic'),
    url('raleway/raleway-v13-latin-italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-italic.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('raleway/raleway-v13-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Raleway Medium'), local('Raleway-Medium'),
    url('raleway/raleway-v13-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-500.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('raleway/raleway-v13-latin-500.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-500italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 500;
  src: url('raleway/raleway-v13-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Medium Italic'), local('Raleway-MediumItalic'),
    url('raleway/raleway-v13-latin-500italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-500italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-500italic.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-500italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-500italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-600 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('raleway/raleway-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
    url('raleway/raleway-v13-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-600.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('raleway/raleway-v13-latin-600.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-600italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 600;
  src: url('raleway/raleway-v13-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway SemiBold Italic'), local('Raleway-SemiBoldItalic'),
    url('raleway/raleway-v13-latin-600italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-600italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-600italic.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-600italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-600italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('raleway/raleway-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Raleway Bold'), local('Raleway-Bold'),
    url('raleway/raleway-v13-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-700.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('raleway/raleway-v13-latin-700.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-700italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 700;
  src: url('raleway/raleway-v13-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'),
    url('raleway/raleway-v13-latin-700italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-700italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-700italic.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-700italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-700italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-800 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('raleway/raleway-v13-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
    url('raleway/raleway-v13-latin-800.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-800.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-800.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-800.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('raleway/raleway-v13-latin-800.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-800italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 800;
  src: url('raleway/raleway-v13-latin-800italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway ExtraBold Italic'), local('Raleway-ExtraBoldItalic'),
    url('raleway/raleway-v13-latin-800italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-800italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-800italic.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-800italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-800italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-900 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 900;
  src: url('raleway/raleway-v13-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Raleway Black'), local('Raleway-Black'),
    url('raleway/raleway-v13-latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-900.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-900.ttf')
      format('truetype'),
    /* Safari, Android, iOS */ url('raleway/raleway-v13-latin-900.svg#Raleway')
      format('svg'); /* Legacy iOS */
}
/* raleway-900italic - latin */
@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 900;
  src: url('raleway/raleway-v13-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Raleway Black Italic'), local('Raleway-BlackItalic'),
    url('raleway/raleway-v13-latin-900italic.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('raleway/raleway-v13-latin-900italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('raleway/raleway-v13-latin-900italic.woff')
      format('woff'),
    /* Modern Browsers */ url('raleway/raleway-v13-latin-900italic.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('raleway/raleway-v13-latin-900italic.svg#Raleway') format('svg'); /* Legacy iOS */
}
