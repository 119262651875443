/* sigmar-one-regular - latin */
@font-face {
  font-family: 'Sigmar One';
  font-style: normal;
  font-weight: 400;
  src: url('sigmar-one/sigmar-one-v10-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Sigmar One Regular'), local('SigmarOne-Regular'),
    url('sigmar-one/sigmar-one-v10-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('sigmar-one/sigmar-one-v10-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('sigmar-one/sigmar-one-v10-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('sigmar-one/sigmar-one-v10-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('sigmar-one/sigmar-one-v10-latin-regular.svg#SigmarOne') format('svg'); /* Legacy iOS */
}
